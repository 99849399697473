<template>
    <b-modal id="modal-termos-v2" v-model="isOpen" hide-footer hide-header :size="existVideo ? 'lg' : null" :data-theme="theme">
        <section class="close-button">
            <button @click="setVisibility">X</button>
        </section>
        <section class="terms-modal-body">
            <div class="body-content">
                <div class="header">
                    <div class="title">Termos de Uso</div>
                    <div class="subtitle">É necessário estar ciente aos termos de uso!</div>
                </div>
                <div v-if="step === 0" class="animationOpacity2">
                    <b-form-group label="Vídeo de Introdução" label-for="descricao">
                        <iframe
                            class="imagem"
                            height="100%"
                            v-if="techOrder === 'panda'"
                            width="100%"
                            :src="midiaTermosUrl"
                            style="border: 0; pointer-events: all; height: 45vh"
                        ></iframe>
                        <video ref="videoPlayer"
                            v-else-if="techOrder !== 'iframe'"
                            class="video-js widthVideoPlayer" 
                            :tech-order="techOrder" 
                            :autoplay="autoplay" 
                            :controls="true"
                            v-on:pause="onVideoPause"
                            @changed="playerStateChanged($event)"
                            >
                            <source :src="src" :type="type">
                        </video>
                        <div v-html="src" v-else>

                        </div>
                    </b-form-group>
                </div>
                <div v-if="step === 1" class="animationOpacity2">
                    <b-form-group label="Termos de Uso" label-for="descricao">
                        <div id="spaceText" class="spaceText">
                            <span v-html="termosPainel"></span>
                        </div>
                    </b-form-group>
                </div>
                <div class="footer animationOpacity" v-if="step === 0">
                    <button class="next" @click="next">Próxima etapa</button>
                </div>
                <div class="footer animationOpacity" v-if="step === 1">
                    <button class="prev" v-if="existVideo" @click="prev">Voltar</button>
                    <button class="next" @click="salvarDados()">Confirmar</button>
                </div>
            </div>
        </section>
    </b-modal>    
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';
import 'videojs-youtube';
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
    components:{
  },
props:['siteid'],
  data(){
    return{
        termosPainel:'',
        midiaTermos:null,
        midiaTermosUrl:null,
        mediaTypeLesson:'',
        step:0,
        autoplay: true,
        player: null,
        techOrder: "youtube",
        type: "video/youtube",
        src: "",
        statusCheck:false,
        toEnd:false,
        existVideo:true,
        isOpen: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
  },
  methods:{
    scrollFunction () {
        let element = document.getElementById('spaceText');
        if(element.scrollHeight - element.scrollTop === element.clientHeight){
            this.toEnd = true;
        }else{
            this.toEnd = false;
        }
    },
    reachedEndOfText(reached){
        if (reached) {
            this.toEnd = true;
            //console.log('to end xxxxxxxxxxxx')  
        }
    },
    salvarDados(){
        this.$root.$emit('loadOn');
        let data = {
            id: '/meta/_termos_'+this.siteid,
            value:'accepted'
        }
        serviceMember
        .postID(data)
        .then((resp) => {
          //console.log("accept terms", resp);
          this.$root.$emit('bv::hide::modal', 'modal-termos-v2', '#btnShow')
          this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    prev(){
        this.getMetaTermosVideo();
        this.step = this.step-1;
    },
    next(){
        this.step = this.step+1
    },
    async getMetaTermosVideo(){
      this.$root.$emit('loadOn');
      let resp = await this.$store.dispatch("fetchMetas", ["introductoryVideo"])
      var data = JSON.parse(resp.introductoryVideo);
      if(resp.introductoryVideo === null || data.url === null){
          this.step = 1;
          this.existVideo = false;
      }else{
          //console.log("meta video termos", data);
          this.midiaTermos = data.thumb;
          this.midiaTermosUrl = data.url;
          this.src = data.url;
          this.techOrder = data.type;
          if(data.type === 'vimeo'){
              this.type = "video/vimeo";
          }else if(data.type === 'youtube'){
              this.type = "video/youtube";
          }else{
              return
          }
          //console.log(this.src, this.type);
          setTimeout(() => {
              this.getPlayer();
          }, 1000);
      }
      this.$root.$emit('loadOff');
    },
    async getMetaTermosTxt(){
      let resp = await this.$store.dispatch("fetchMetas", ["termsOfUse"])
      this.termosPainel = resp.termsOfUse;
    },
    showModalUpload(){
      this.$root.$emit("videotermos");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    playerDispose(){ 
        this.player.dispose(); 
    },
    playerGetTime(){
        return this.player.currentTime();
    },
    getPlayer(){
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            //console.log('onPlayerReady', this);
        });
        //console.log("xxx", this.player);
    },
    onVideoPause: function () {
        //console.log('video is paused')
    },
    play() {
        this.player.play();
    },
    pause() {
        this.player.pause();
    },
    playerStateChanged: function(playerCurrentState) {
        //console.log('player current update state', playerCurrentState);
    },
    setVisibility() {
      this.isOpen = !this.isOpen;
    },
  },
  mounted(){
    this.getMetaTermosTxt();
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === 'modal-termos-v2'){
          this.getMetaTermosTxt();
          this.getMetaTermosVideo();
      }
    })
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
[data-theme="light"] {
  --background: #ffffff;
  --text-color: #16171a;
  --closes-bg: #f7f7f7;
}
[data-theme="dark"] {
  --background: #16171a;
  --text-color: #ffffff;
  --closes-bg: #1e2024;
}
.default-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--text-color);
}

.terms-modal-body {
  display: flex;
  flex-direction: column;

  &-content {
    max-width: 350px;
  }

  .body-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}

.hide{
    display: none !important;
}

.disableBtn{
    background: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    color: #81858E !important;
    pointer-events: none;
}

.spaceText{
    height: 30vh;
    overflow: auto;
    p span {
        color: var(--fontcolor2-v2);
    }
}

.footer{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 24px;
}
.next{
    @extend .default-text;
  font-weight: 700;
  font-size: 14px;
  color: #16181a;
  min-width: 50%;
  outline: none;
  border: none;
  background: var(--maincolor);
  border-radius: 50px;
  padding: 15px 0;
  transition: ease-in-out 200ms;

  &:hover {
    transform: scale3d(0.9, 0.9, 0.9);
  }
}
.prev{
    @extend .default-text;
  font-weight: 700;
  font-size: 14px;
  color: #16181a;
  min-width: 50%;
  outline: none;
  border: none;
  background: var(--fontcolor2-v2);
  border-radius: 50px;
  padding: 15px 0;
  transition: ease-in-out 200ms;

  &:hover {
    transform: scale3d(0.9, 0.9, 0.9);
  }
}

.btn-openModal{
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    cursor: pointer;
    .text{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }
}

.btn-openModalPreview{
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    img{
        border-radius: 3px;
    }
    .imagePreviewThumb{
        height: 150px;
    }
}

    .title{
        @extend .default-text;
        margin-bottom: 30px;
    }
    .subtitle{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
        margin-bottom: 24px;

    }
    .close-button {
    display: flex;
    justify-content: flex-end;

    button {
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: var(--closes-bg);
        padding: 15px;
        color: #6f6f6f;
        font-size: 15px;
        font-weight: 700;
  }
}
</style>